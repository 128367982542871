<template>
  <div class="row">
    <!-- <div class="col-12">
      <current></current>
    </div> -->
    <div class="col-12">
      <list></list>
    </div>
  </div>
</template>

<script>
// import current from "./current.vue";
import list from "./list.vue";
export default {
  components: {
    list
    // current
  }
};
</script>

<style></style>
