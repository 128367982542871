<template>
  <div class="card card-custom card-stretch">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div v-if="loading">
        <button
          type="button"
          class="btn btn-success spinner spinner-white spinner-right"
        >
          Carregando
        </button>
      </div>
      <div class="table-responsive" v-else>
        <div
          class="card card-custom gutter-b bg-diagonal bg-diagonal-light-warning"
          v-if="list.items.length === 0"
        >
          <div class="card-body">
            <div
              class="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap"
            >
              <div class="d-flex flex-column mr-5">
                <a href="#" class="h4 text-dark text-hover-primary mb-5">
                  Sem Movimentações
                </a>
                <p class="text-dark-50">
                  Você não possue faturas, realize upgrade do seu plano para ter
                  acesso a diversas funções.
                </p>
              </div>
              <div class="ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0">
                <router-link
                  to="/plans"
                  class="btn font-weight-bolder text-uppercase btn-warning py-4 px-6"
                >
                  Ir para Planos
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <table class="table table-borderless table-vertical-center" v-else>
          <thead>
            <tr>
              <th class="p-0 w-150px">Data</th>
              <th class="p-0 w-250px">Descrição</th>
              <th class="p-0 w-150px">Valor</th>
              <th class="p-0 w-150px">Status</th>
              <th class="p-0 w-50px text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.items" :key="index">
              <td class="pl-0">
                <a
                  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ item.created_at }}</a
                >
              </td>
              <td class="pl-0">
                <a
                  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase"
                >
                  {{ item.items[0].description }}
                </a>
              </td>
              <td class="pl-0">
                <a
                  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg  text-uppercase"
                  >{{ item.total }}</a
                >
              </td>
              <td>
                <div
                  class="d-flex flex-column w-100 mr-2"
                  v-if="status[item.status]"
                >
                  <span
                    class="label label-xl label-pill label-inline mr-2"
                    :class="statusClass(item.status)"
                    >{{ status[item.status].name }}</span
                  >
                </div>
              </td>
              <td class="text-right pr-0">
                <a
                  :href="item.secure_url"
                  target="_blank"
                  class="btn btn-icon btn-secondary btn-sm"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                    <inline-svg
                      src="media/svg/icons/Shopping/Credit-card.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "",
  mounted() {
    this.getInvoices();
  },
  data() {
    return {
      loading: true,
      status: {
        expired: {
          class: "",
          name: "Expirado"
        },
        pending: {
          class: "label-warning",
          name: "Pendente"
        },
        canceled: {
          class: "",
          name: "Cancelado"
        },
        paid: {
          class: "label-success",
          name: "Pago"
        }
      },
      list: {},
      listOld: [
        {
          month: "Maio",
          year: "2021",
          quota: "1000000",
          consume: "680000",
          consents: "521235",
          payment: false
        },
        {
          month: "Abril",
          year: "2021",
          quota: "1000000",
          consume: "1200000",
          consents: "521235",
          payment: true
        },
        {
          month: "Março",
          year: "2021",
          quota: "1000000",
          consume: "100000",
          consents: "521235",
          payment: true
        }
      ]
    };
  },
  methods: {
    statusClass(status) {
      if (!this.status[status]) return "warning";
      return this.status[status].class || "warning";
    },
    percent(item) {
      return Math.round((item.consume / item.quota) * 100) || 0;
    },
    getInvoices() {
      ApiService.query("iugu/invoices").then(result => {
        this.loading = false;
        this.list = result.data;
      });
    }
  }
};
</script>

<style></style>
