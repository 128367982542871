<template>
  <div>
    <Navigation></Navigation>
    <invoices :list="list"></invoices>
  </div>
</template>

<script>
import Navigation from "@/view/layout/navigation";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import invoices from "./comps/invoices";
import ApiService from "@/core/services/api.service";
export default {
  name: "invoices-list",
  components: {
    Navigation,
    invoices
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Banners" }]);
    this.getInvoices();
  },
  methods: {
    getInvoices() {
      ApiService.query("iugu/invoices").then(() => {
        // context.commit(SET_CARDS, result.data.docs);
      });
    }
  }
};
</script>
